
import { mapGetters } from 'vuex';

// @group page
export default {
  computed: {
    ...mapGetters({
      isPageTransitionActive: 'ui/isPageTransitionActive'
    })
  },
  watch: {
    // As soon as the page transition is launched we make it stop at the end
    // 1100 is the total time of the animation in ms
    isPageTransitionActive() {
      setTimeout(() => this.$store.commit('ui/stopPageTransitionAnimation'), 1800);
    }
  }
};
