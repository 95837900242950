
// @group elements
export default {
  props: {
    isExternal: {
      type: Boolean,
      default: false
    },
    arrowRight: {
      type: Boolean,
      default: false
    },
    arrowLeft: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: '/'
    },
    target: {
      type: String,
      default: '_self'
    },
    variant: {
      type: String,
      default: 'primary'
    }
  }
};
