import { groq } from '@nuxtjs/sanity';

/**
 * With this query, we get all the content from the Sanity -> Settings
 */

export default function getSiteSettingsQuery(locale) {
  return groq`*[_type=="page" && __i18n_lang=="${locale}"][0] {
    "socialMedia":  *[_type=="socialMedia"][0],

    "artists": *[_type=="artist" &&
      !(_id in path("drafts.**"))
    ] {
      ...,
      // "imageObjectData": profilePicture.refImage->{upload},
      "imageObjectData": {
        "metadata": profilePicture.refImage->{"asset": upload.asset->,
        "crop": upload.crop, "hotspot": upload.hotspot},
        "alt": profilePicture.alt
      }
    },

    "infoPageNavigation": *[_type=="infoPageNavigation" && !(_id in path("drafts.**"))][0] {
      list[]->{url, title}
    },

    "festival": *[_type=="festival"][0],

    "sitemap": *[
      (_type=="page" || _type=="blog" || _type=="news") &&
      __i18n_lang=="${locale}" &&
      !(_id in path("drafts.**"))
    ] { url, _id, __i18n_lang, isInfopage, title },

    "legal": *[
      _type=="legal" &&
      __i18n_lang=="${locale}"
    ] { conditions->{slug}, imprint->{slug}, privacy->{slug} },

    "staticText": *[_type=="staticText" && __i18n_lang=="${locale}"] { entry, _id }
  }`;
}
