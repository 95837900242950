export const state = () => ({
  isOverlayActive: false,
  activeFilter: null,
  isModalActive: false,
  modalContent: Object,
  isPageTransitionActive: false,
  layout: '',
  easterEgg: false
});

export const mutations = {
  toggleOverlay(state, value) {
    state.isOverlayActive = value;
  },
  toggleEasterEgg(state, value) {
    state.easterEgg = value;
  },
  setModalContent(state, value) {
    state.modalContent = value;
  },
  setActiveFilter(state, value) {
    state.activeFilter = value;
  },
  toggleModal(state, value) {
    state.isModalActive = value;
  },
  startPageTransitionAnimation(state) {
    state.isPageTransitionActive = true;
  },
  stopPageTransitionAnimation(state) {
    state.isPageTransitionActive = false;
  },
  setPageLayout(state, layout) {
    state.layout = layout;
  }
};

export const getters = {
  isOverlayActive(state) {
    return state.isOverlayActive;
  },
  isEasterEggActive(state) {
    return state.easterEgg;
  },
  isModalActive(state) {
    return state.isModalActive;
  },
  getModalContent(state) {
    return state.modalContent;
  },
  getActiveFilter(state) {
    return state.activeFilter;
  },
  isLoaderActive(state) {
    return state.isLoaderActive;
  },
  isPageTransitionActive(state) {
    return state.isPageTransitionActive;
  },
  getPageLayout(state) {
    return state.layout;
  }
};
