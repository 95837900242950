import getSiteSettingsQuery from '~/queries/getSiteSettingsQuery';

export const state = () => ({
  legal: [],
  socialMedia: [],
  staticText: {},
  sitemap: [],
  artists: [],
  festival: [],
  infoPageNavigation: []
});

export const mutations = {
  setStaticText(state, staticText) {
    state.staticText = staticText;
  },
  setSitemap(state, sitemap) {
    state.sitemap = sitemap;
  },
  setLegal(state, legal) {
    state.legal = legal;
  },
  setSocialMedia(state, socialMedia) {
    state.socialMedia = socialMedia;
  },
  setArtists(state, artists) {
    state.artists = artists;
  },
  setFestival(state, festival) {
    state.festival = festival;
  },
  setInfoPageNavigation(state, infoPageNavigation) {
    state.infoPageNavigation = infoPageNavigation;
  }
};

export const getters = {
  staticText(state) {
    return state.staticText;
  },
  sitemap(state) {
    return state.sitemap;
  },
  legal(state) {
    return state.legal;
  },
  socialMedia(state) {
    return state.socialMedia;
  },
  festival(state) {
    return state.festival;
  },
  getStaticTextByKey: (state) => (key) => {
    return state.staticText.find(({ entry }) => entry.key === key);
  },
  getSlugByRef: (state) => (_ref) => {
    return state.sitemap.find((page) => page._id === _ref);
  },
  artists(state) {
    return state.artists;
  },
  infoPageNavigation(state) {
    return state.infoPageNavigation;
  }
};

export const actions = {
  async load({ commit, rootState }) {
    const locale = rootState.navigation.locale;
    const query = getSiteSettingsQuery(locale);
    const result = await this.$sanity.fetch(query);

    commit('setArtists', result?.artists ?? []);
    commit('setFestival', result?.festival ?? []);
    commit('setSitemap', result?.sitemap ?? []);
    commit('setStaticText', result?.staticText ?? []);
    commit('setLegal', result?.legal ?? []);
    commit('setSocialMedia', result?.socialMedia?.socialMedia ?? []);
    commit('setInfoPageNavigation', result?.infoPageNavigation?.list ?? []);
  }
};
