
// This custom field component is used by the <Form/> component.
// see here the doc to create your own custom component:
// https://vue-generators.gitbook.io/vue-generators/fields/custom_fields

import { abstractField } from 'vue-form-generator';

export default {
  mixins: [abstractField],
  data() {
    return {
      isValid: false,
      inputPhoneNumber: null,
      errorMessage: {
        en: 'Please write a valid phone number.',
        fr: 'Veuillez entrer un numéro correct.',
        de: 'Bitte geben Sie eine korrekte Nummer ein.',
        it: 'Inserire un numero corretto.'
      }
    };
  },
  watch: {
    isValid() {
      this.addErrorToInput(this.inputPhoneNumber, !this.isValid);
    }
  },
  mounted() {
    // We want the form to send only if the phone number format is respected,
    // so we add en error to the input if not valid
    this.inputPhoneNumber =
      this.$refs.vuePhoneNumber.$children[1].$el.getElementsByTagName('input')[0];
    this.inputPhoneNumber.classList.add('custom-phone-number-input');
    if (this.schema.required) {
      this.addErrorToInput(this.inputPhoneNumber, true);
    }
  },
  methods: {
    addErrorToInput(input, error) {
      if (error) {
        input.setCustomValidity(this.errorMessage[this.$i18n.locale]);
      } else {
        input.setCustomValidity('');
      }
    }
  }
};
