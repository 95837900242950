
import { mapGetters } from 'vuex';
export default {
  name: 'FooterComponent',

  computed: {
    ...mapGetters({
      isLoaderActive: 'ui/isLoaderActive',
      socialMedia: 'settings/socialMedia'
    })
  }
};
