// This plugin will provide all the methods for getting data from the settings store
export default {
  methods: {
    getIsUserLogged() {
      return JSON.parse(localStorage.getItem('isUserLogged'));
    },
    setIsUserLogged(isLogged) {
      localStorage.setItem('isUserLogged', JSON.stringify(isLogged));
    }
  }
};
