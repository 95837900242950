export const SanityContent = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-content.js' /* webpackChunkName: "components/sanity-content" */).then(c => wrapFunctional(c.default || c))
export const SanityFile = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-file.js' /* webpackChunkName: "components/sanity-file" */).then(c => wrapFunctional(c.default || c))
export const SanityImage = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-image.js' /* webpackChunkName: "components/sanity-image" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeI2 = () => import('../../components/elements/Animations/CustomFadeI2.vue' /* webpackChunkName: "components/animation-custom-fade-i2" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIn = () => import('../../components/elements/Animations/CustomFadeIn.vue' /* webpackChunkName: "components/animation-custom-fade-in" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFlagIn = () => import('../../components/elements/Animations/CustomFlagIn.vue' /* webpackChunkName: "components/animation-custom-flag-in" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFlagInSecondary = () => import('../../components/elements/Animations/CustomFlagInSecondary.vue' /* webpackChunkName: "components/animation-custom-flag-in-secondary" */).then(c => wrapFunctional(c.default || c))
export const AnimationGsapFadeInExample = () => import('../../components/elements/Animations/GsapFadeIn_Example.vue' /* webpackChunkName: "components/animation-gsap-fade-in-example" */).then(c => wrapFunctional(c.default || c))
export const AnimationGsapScrollTranslate = () => import('../../components/elements/Animations/GsapScrollTranslate.vue' /* webpackChunkName: "components/animation-gsap-scroll-translate" */).then(c => wrapFunctional(c.default || c))
export const DocumentArtist = () => import('../../components/document/Artist.vue' /* webpackChunkName: "components/document-artist" */).then(c => wrapFunctional(c.default || c))
export const DocumentErrorPage = () => import('../../components/document/ErrorPage.vue' /* webpackChunkName: "components/document-error-page" */).then(c => wrapFunctional(c.default || c))
export const DocumentNews = () => import('../../components/document/News.vue' /* webpackChunkName: "components/document-news" */).then(c => wrapFunctional(c.default || c))
export const DocumentPage = () => import('../../components/document/Page.vue' /* webpackChunkName: "components/document-page" */).then(c => wrapFunctional(c.default || c))
export const PageCookie = () => import('../../components/page/Cookie.vue' /* webpackChunkName: "components/page-cookie" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../components/page/Footer.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/page/Header.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderArtist = () => import('../../components/page/HeaderArtist.vue' /* webpackChunkName: "components/page-header-artist" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderNews = () => import('../../components/page/HeaderNews.vue' /* webpackChunkName: "components/page-header-news" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/page/Loader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const PageScrollTop = () => import('../../components/page/ScrollTop.vue' /* webpackChunkName: "components/page-scroll-top" */).then(c => wrapFunctional(c.default || c))
export const PageTransitionElements = () => import('../../components/page/TransitionElements.vue' /* webpackChunkName: "components/page-transition-elements" */).then(c => wrapFunctional(c.default || c))
export const ContentAdminForms = () => import('../../components/content/AdminForms.vue' /* webpackChunkName: "components/content-admin-forms" */).then(c => wrapFunctional(c.default || c))
export const ContentArtistCard = () => import('../../components/content/ArtistCard.vue' /* webpackChunkName: "components/content-artist-card" */).then(c => wrapFunctional(c.default || c))
export const ContentEasterEgg = () => import('../../components/content/EasterEgg.vue' /* webpackChunkName: "components/content-easter-egg" */).then(c => wrapFunctional(c.default || c))
export const ContentFaq = () => import('../../components/content/Faq.vue' /* webpackChunkName: "components/content-faq" */).then(c => wrapFunctional(c.default || c))
export const ContentFullsizeImage = () => import('../../components/content/FullsizeImage.vue' /* webpackChunkName: "components/content-fullsize-image" */).then(c => wrapFunctional(c.default || c))
export const ContentLineUp = () => import('../../components/content/LineUp.vue' /* webpackChunkName: "components/content-line-up" */).then(c => wrapFunctional(c.default || c))
export const ContentMediaList = () => import('../../components/content/MediaList.vue' /* webpackChunkName: "components/content-media-list" */).then(c => wrapFunctional(c.default || c))
export const ContentNewsPreview = () => import('../../components/content/NewsPreview.vue' /* webpackChunkName: "components/content-news-preview" */).then(c => wrapFunctional(c.default || c))
export const ContentNewsletter = () => import('../../components/content/Newsletter.vue' /* webpackChunkName: "components/content-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ContentPartners = () => import('../../components/content/Partners.vue' /* webpackChunkName: "components/content-partners" */).then(c => wrapFunctional(c.default || c))
export const ContentSlideshow = () => import('../../components/content/Slideshow.vue' /* webpackChunkName: "components/content-slideshow" */).then(c => wrapFunctional(c.default || c))
export const ContentTeaser = () => import('../../components/content/Teaser.vue' /* webpackChunkName: "components/content-teaser" */).then(c => wrapFunctional(c.default || c))
export const ContentTextBlock = () => import('../../components/content/TextBlock.vue' /* webpackChunkName: "components/content-text-block" */).then(c => wrapFunctional(c.default || c))
export const ContentVideoAutoplay = () => import('../../components/content/VideoAutoplay.vue' /* webpackChunkName: "components/content-video-autoplay" */).then(c => wrapFunctional(c.default || c))
export const ContentYoutubeVideo = () => import('../../components/content/YoutubeVideo.vue' /* webpackChunkName: "components/content-youtube-video" */).then(c => wrapFunctional(c.default || c))
export const ContentLineUpPreview = () => import('../../components/content/lineUpPreview.vue' /* webpackChunkName: "components/content-line-up-preview" */).then(c => wrapFunctional(c.default || c))
export const ContentAllNews = () => import('../../components/content/AllNews/Index.vue' /* webpackChunkName: "components/content-all-news" */).then(c => wrapFunctional(c.default || c))
export const  TicketButton = () => import('../../components/elements/ TicketButton.vue' /* webpackChunkName: "components/ ticket-button" */).then(c => wrapFunctional(c.default || c))
export const AccreditationForm = () => import('../../components/elements/AccreditationForm.vue' /* webpackChunkName: "components/accreditation-form" */).then(c => wrapFunctional(c.default || c))
export const Back = () => import('../../components/elements/Back.vue' /* webpackChunkName: "components/back" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/elements/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const CredentialForm = () => import('../../components/elements/CredentialForm.vue' /* webpackChunkName: "components/credential-form" */).then(c => wrapFunctional(c.default || c))
export const FieldDatePicker = () => import('../../components/elements/FieldDatePicker.vue' /* webpackChunkName: "components/field-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FieldPhoneNumber = () => import('../../components/elements/FieldPhoneNumber.vue' /* webpackChunkName: "components/field-phone-number" */).then(c => wrapFunctional(c.default || c))
export const FileDownload = () => import('../../components/elements/FileDownload.vue' /* webpackChunkName: "components/file-download" */).then(c => wrapFunctional(c.default || c))
export const FixedBackground = () => import('../../components/elements/FixedBackground.vue' /* webpackChunkName: "components/fixed-background" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../components/elements/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitch = () => import('../../components/elements/LanguageSwitch.vue' /* webpackChunkName: "components/language-switch" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/elements/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/elements/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const MenuOverlay = () => import('../../components/elements/MenuOverlay.vue' /* webpackChunkName: "components/menu-overlay" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/elements/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationTree = () => import('../../components/elements/NavigationTree.vue' /* webpackChunkName: "components/navigation-tree" */).then(c => wrapFunctional(c.default || c))
export const Picture = () => import('../../components/elements/Picture.vue' /* webpackChunkName: "components/picture" */).then(c => wrapFunctional(c.default || c))
export const Richtext = () => import('../../components/elements/Richtext.vue' /* webpackChunkName: "components/richtext" */).then(c => wrapFunctional(c.default || c))
export const Section = () => import('../../components/elements/Section.vue' /* webpackChunkName: "components/section" */).then(c => wrapFunctional(c.default || c))
export const SocialMedia = () => import('../../components/elements/SocialMedia.vue' /* webpackChunkName: "components/social-media" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/elements/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/elements/AccordionGroup/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionGroup = () => import('../../components/elements/AccordionGroup/index.vue' /* webpackChunkName: "components/accordion-group" */).then(c => wrapFunctional(c.default || c))
export const FormHoneypotForm = () => import('../../components/elements/Form/HoneypotForm.vue' /* webpackChunkName: "components/form-honeypot-form" */).then(c => wrapFunctional(c.default || c))
export const Form = () => import('../../components/elements/Form/index.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
