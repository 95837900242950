const sanityClient = require('@sanity/client');

export default async function ({ route, isHMR, $config, store, req }) {
  if (isHMR) { return; }
  try {
    const {
      query: { preview, documentId }
    } = route;

    if (!preview) {
      store.commit('preview/setPreviewMode', false);
      return;
    }

    const client = sanityClient({
      projectId: $config.api.projectId,
      dataset: $config.api.dataset,
      apiVersion: $config.api.apiVersion,
      useCdn: false,
      token:
        // eslint-disable-next-line
        'skAOpexkBk7GqNXpxYCezecocB0OupCNJrzG6oOMc1OhPZhhe3ZNlPqHDaUB5MtyMCiTzkLnhuIAjXyyBdWYGwZ0XuvoZ6qLyjm2V9Aailkienv92TIrewk9x2WB5H0gjpPcr3jTSqyCj3HxMalxSl76Ph7JV8PJzX5cYifAEDIuFFXFR9BO'
    });

    const query = `*[_id=="${documentId}"][0]`;
    const result = await client.fetch(query);
    store.commit('preview/setPreviewMode', true);
    store.commit('preview/setDocumentData', result || {});
  } catch (e) {
    throw new Error(`previewMode.js: ${e}`);
  }
}
