
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      isOverlayActive: 'ui/isOverlayActive'
    })
  },
  watch: {
    isOverlayActive() {
      document.documentElement.classList[this.isOverlayActive ? 'add' : 'remove']('no-scroll');
    },
    $route() {
      this.$store.commit('ui/toggleOverlay', false);
    }
  }
};
