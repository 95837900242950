export default {
  methods: {
    $backgroundClassPicker(color) {
      let cssClass;
      const isYellow = color === 'yellow';
      const isWhite = color === 'white';
      const isGreen = color === 'green';
      const isTransparent = color === 'transparent';

      switch (true) {
        case isYellow:
          cssClass = 'bg-palette-yellow';
          break;
        case isGreen:
          cssClass = 'bg-palette-green';
          break;
        case isWhite:
          cssClass = 'bg-white';
          break;
        case isTransparent:
          cssClass = 'bg-transparent';
          break;
        default:
          cssClass = 'bg-transparent';
          break;
      }

      return cssClass;
    }
  }
};
